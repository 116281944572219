import React from "react"
import cx from "classnames"
import loadable from "@loadable/component"

import Layout from "../components/layout"
import { IMG } from "../const"
import {
  TECHNOLOGIES,
  DELIVERABLES,
  CHALLENGES,
  SCOPE,
  FEATURES,
  PROJECTS_TEAM,
} from "../data/cyber-insurer"
import "../assets/styles/commons/_heading-numbers.scss"
import "../assets/styles/commons/lists/_common__list__techLogos-rounded.scss"
import "../assets/styles/commonsGlossy.scss"
import * as styles from "../assets/styles/cyber-insurer.module.scss"
const ServicesList = loadable(() => import("../components/services-list"))
const ContactForm = loadable(() => import("../components/contact-form"))

const Image = ({ image }) => (
  <div className={styles.imageBox}>
    <picture>
      <source
        srcSet={`${IMG.CYBER_INSURER}/${image.name}@3x.avif`}
        type="image/avif"
      />
      <source
        srcSet={`${IMG.CYBER_INSURER}/${image.name}3x.webp`}
        type="image/webp"
      />
      <source srcSet={`${IMG.CYBER_INSURER}/${image.name}@3x.jpg`} />
      <img
        src={`${IMG.CYBER_INSURER}/${image.name}@3x.jpg`}
        alt={image.alt}
        width={image.width}
        height={image.height}
        loading="lazy"
      />
    </picture>
  </div>
)

const Images = ({ images }) => {
  if (images.length === 2) {
    return (
      <div className={styles.doubleImages}>
        {images.map((image, index) => (
          <Image key={index} image={image} />
        ))}
      </div>
    )
  }
  if (images.length === 1) {
    return <Image image={images[0]} />
  }
  return
}

const CyberInsurance = () => (
  <Layout pageTitle="Cyber Insurance">
    {({
      handleOpenConfirmation,
      isOpenConfirmation,
      setConfirmationOption,
    }) => {
      return (
        <>
          <main className={cx(styles.cyberInsuranceBlock)}>
            <article>
              <div className={styles.heading}>
                <h1>
                  US Cyber Insurer Offers Cybersecurity Solutions for Your
                  Business
                </h1>

                <div className={styles.headingText}>
                  <p>
                    The startup delivers
                    <strong> holistic cyber risk management</strong> tools to
                    help protect businesses from cyber-attacks. The idea of the
                    project is to{" "}
                    <strong>enhance cybersecurity monitoring</strong> and
                    <strong> employee training</strong>, delivering cutting-edge
                    solutions to small and medium-sized businesses.
                  </p>
                  <ul className={styles.details}>
                    <li>
                      <dl>
                        <dt>Industry:</dt>
                        <dd>Insurance</dd>
                      </dl>
                    </li>
                    <li>
                      <dl>
                        <dt>Country:</dt>
                        <dd>USA</dd>
                      </dl>
                    </li>
                  </ul>
                </div>

                <div className={styles.mainImage}>
                  <div className={styles.imageBox}>
                    <picture>
                      <source
                        media="(max-width: 500px)"
                        srcSet={`${IMG.CYBER_INSURER}/main-mobile@3x.avif`}
                        type="image/avif"
                      />
                      <source
                        media="(max-width: 500px)"
                        srcSet={`${IMG.CYBER_INSURER}/main-mobile@3x.webp`}
                        type="image/webp"
                      />
                      <source
                        media="(max-width: 500px)"
                        srcSet={`${IMG.CYBER_INSURER}/main-mobile@3x.jpg`}
                      />

                      <source
                        media="(min-width: 501px)"
                        srcSet={`${IMG.CYBER_INSURER}/main@3x.avif`}
                        type="image/avif"
                      />
                      <source
                        media="(min-width: 501px)"
                        srcSet={`${IMG.CYBER_INSURER}/main@3x.webp`}
                        type="image/webp"
                      />
                      <source
                        media="(min-width: 501px)"
                        srcSet={`${IMG.CYBER_INSURER}/main@3x.jpg`}
                      />
                      <img
                        src={`${IMG.CYBER_INSURER}/main@3x.jpg`}
                        alt="US Cyber Insurer"
                        width="1164"
                        height="599"
                        loading="lazy"
                      />
                    </picture>
                  </div>
                </div>
              </div>
              <div className={styles.listBlock}>
                <ul className={styles.mainList}>
                  {/* --- 1 --- */}
                  <li>
                    <div className={styles.twoColumns}>
                      <div className={styles.textFirstBlock}>
                        <h2>Client & Product</h2>
                        <p>
                          LaSoft partnered with Zala to improve the existing
                          platform and refine and expand the platform’s
                          functionality, delivering an exceptional digital
                          product that exceeded the client’s goals. The platform
                          now includes enhanced user management, analytics, and
                          marketplace features, giving the cyber startup an
                          advantage in the cyber insurance market.
                        </p>
                      </div>
                      <div className={styles.imageFirstBlock}>
                        <div className={styles.imageBox}>
                          <picture>
                            <source
                              srcSet={`${IMG.CYBER_INSURER}/client-product@3x.avif`}
                              type="image/avif"
                            />
                            <source
                              srcSet={`${IMG.CYBER_INSURER}/client-product@3x.webp`}
                              type="image/webp"
                            />
                            <source
                              srcSet={`${IMG.CYBER_INSURER}/client-product@3x.jpg`}
                            />
                            <img
                              src={`${IMG.CYBER_INSURER}/client-product@3x.jpg`}
                              alt="Client & Product"
                              width="570"
                              height="384"
                              loading="lazy"
                            />
                          </picture>
                        </div>
                      </div>
                    </div>
                  </li>
                  {/* --- 2 --- */}
                  <li className={styles.textCenter}>
                    <div>
                      <h2>Project Deliverables</h2>
                      <ul className={styles.deliverablesList}>
                        {DELIVERABLES.map(item => (
                          <li key={item.icon}>
                            <div className={styles.iconWrapper}>
                              <img
                                src={`${IMG.CYBER_INSURER}/icons/${item.icon}.svg`}
                                width="32"
                                height="32"
                                loading="lazy"
                                alt={item.title}
                              />
                            </div>
                            <h3>{item.title}</h3>
                            <p>{item.text}</p>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </li>
                  {/* --- 3 --- */}
                  <li>
                    <div className={styles.twoColumns}>
                      <div className={styles.thirdBlockText}>
                        <h2>Our Efforts and Scope</h2>
                        <p>
                          Our collaboration with the US cyber insurer and the
                          Zala team introduced the core changes into the project
                          to optimize and deliver high-quality solutions to
                          secure businesses from cyber attacks.
                        </p>
                      </div>
                    </div>
                    <div className={styles.scopeWrapper}>
                      <ServicesList services={SCOPE} />
                    </div>
                  </li>
                  {/* --- 4 --- */}
                  <li className={styles.textCenter}>
                    <div>
                      <h2>Technologies</h2>
                      <p className={styles.textBox}>
                        Our experts contributed their technical proficiency,
                        problem-solving expertise, and a deep understanding of
                        custom software development.
                      </p>
                      <div className={styles.technologyWrapper}>
                        <ul className="common__list__techLogos-rounded">
                          {TECHNOLOGIES.map(technology => (
                            <li key={technology.name}>
                              <figure>
                                <img
                                  src={`${IMG.ICONS}/logos/${technology.icon}`}
                                  width="32"
                                  height="32"
                                  alt={technology.name}
                                />
                                <figcaption>{technology.name}</figcaption>
                              </figure>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </li>
                  {/* --- 5 --- */}
                  <li className={styles.textCenter}>
                    <h2>Main challenges</h2>
                    <p className={styles.textBox}>
                      The expertise and professionalism of the teams working on
                      the project ensured that we overcame all challenges and
                      achieved the planned results, adding value to the product.
                    </p>
                    <ul className={styles.challengesList}>
                      {CHALLENGES.map(item => (
                        <li key={item.id}>
                          <h3>{item.title}</h3>
                          <p>{item.text}</p>
                        </li>
                      ))}
                    </ul>
                  </li>
                  {/* --- 6 --- */}
                  <li className={styles.textCenter}>
                    <h2>Project’s Team</h2>
                    <p className={styles.textBox}>
                      Our experts contributed their technical proficiency,
                      problem-solving expertise, and a deep understanding of
                      custom software development.
                    </p>
                    <div className={styles.teamWrapper}>
                      <img
                        className={styles.teamImg}
                        src={`${IMG.CYBER_INSURER}/projects-team.svg`}
                        alt="Project’s Team"
                        width="1012"
                        height="324"
                        loading="lazy"
                      />
                      {/* mobile only */}
                      <div className={styles.teamMobile}>
                        {PROJECTS_TEAM.map((item, index) => (
                          <div key={index}>
                            <div className={styles.teamListName}>
                              {item.name}
                            </div>
                            <ul>
                              {item.team.map(item => (
                                <li key={item} className={styles.teamItem}>
                                  {item}
                                </li>
                              ))}
                            </ul>
                          </div>
                        ))}
                      </div>
                    </div>
                  </li>
                  {/* --- 7 --- */}
                  <li className={styles.textCenter}>
                    <h2>Implemented Features</h2>
                    <p className={styles.textBox}>
                      Our collaborative effort with Zala resulted in a robust,
                      user-friendly, and secure cyber insurance platform.
                    </p>
                    <ul className={styles.featuresList}>
                      {FEATURES.map(item => (
                        <li key={item.id}>
                          <div className={styles.textBlock}>
                            <h3>{item.title}</h3>
                            <p>{item.text}</p>
                          </div>
                          <div>
                            <Images images={item.images} />
                          </div>
                        </li>
                      ))}
                    </ul>
                  </li>
                  {/* --- 8 --- */}
                  <li>
                    <div className={styles.twoColumns}>
                      <div className={styles.lastTextBlock}>
                        <h2>Product Development Outcomes</h2>
                        <p>
                          The development process resulted in a complete digital
                          transformation of the cyber platform, optimizing it
                          without compromising efficiency, improving operational
                          effectiveness, and providing a scalable, feature-rich
                          platform tailored to the needs of small and
                          medium-sized businesses.
                        </p>
                        <p>
                          <strong>
                            Success story: The renowned global specialist
                            insurer has acquired the startup’s cybersecurity
                            platform. This move represents a significant step
                            forward in strengthening cybersecurity protection
                            for SMBs by combining advanced risk management tools
                            and the potential they bring to the cyber market.
                          </strong>
                        </p>
                      </div>
                      <div className={styles.quoteBlock}>
                        <div className={styles.authorImg}>
                          <picture>
                            <source
                              srcSet={`${IMG.CYBER_INSURER}/Layla-Atya@3x.avif`}
                              type="image/avif"
                            />
                            <source
                              srcSet={`${IMG.CYBER_INSURER}/Layla-Atya@3x.webp`}
                              type="image/webp"
                            />
                            <source
                              srcSet={`${IMG.CYBER_INSURER}/Layla-Atya@3x.png`}
                            />
                            <img
                              src={`${IMG.CYBER_INSURER}/Layla-Atya@3x.png`}
                              alt="Layla Atya"
                              width="104"
                              height="104"
                              loading="lazy"
                            />
                          </picture>
                        </div>
                        <blockquote>
                          <p>
                            We’ve partnered with LaSoft for years and appreciate
                            their expertise, commitment to quality, and seamless
                            integration. Their innovative ideas improve the
                            cybersecurity platform for small and medium-sized
                            businesses. We’re grateful for their dedication to
                            creating a scalable, user-friendly solution and
                            thrilled with the results.
                          </p>
                        </blockquote>
                        <div className={styles.authorName}>Layla Atya</div>
                        <div className={styles.authorJob}>
                          CEO Zala International
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </article>
            <section className="footerForm">
              <ContactForm
                formStatID="CommonFooter"
                handleOpenConfirmation={handleOpenConfirmation}
                isOpenConfirmation={isOpenConfirmation}
                setConfirmationOption={setConfirmationOption}
              />
            </section>
          </main>
        </>
      )
    }}
  </Layout>
)

export default CyberInsurance
